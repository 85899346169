import CryptoJS from "crypto-js"; // Ensure CryptoJS is installed

class MsManager {
    /**
     * Standardizes the email address by removing unnecessary characters and formatting.
     * @param {string} email - The raw email address.
     * @returns {string} - The standardized email address.
     */
    standardizeEmail(email) {
        if (!email) return '';

        // Remove whitespaces and accents
        let standardized = email.normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/\s+/g, '');

        // Remove everything between '+' and '@'
        standardized = standardized.replace(/\+.*@/, '@');

        // Remove periods before '@' and ensure no trailing periods
        standardized = standardized.replace(/\.(?=.*@)/g, '').replace(/\.+$/, '');

        // Convert to lowercase and ensure it contains '@'
        standardized = standardized.toLowerCase();
        if (!standardized.includes('@')) return '';

        return standardized;
    }

    /**
     * Hashes the standardized email using SHA-256.
     * @param {string} data - The standardized email address.
     * @returns {string} - The SHA-256 hash of the email.
     */
    hashData(data) {
        return CryptoJS.SHA256(data).toString();
    }

    /**
     * Submits the order data to Microsoft Ads with Enhanced Conversions.
     * @param {number} value - The value of the purchase.
     * @param {string} currency - The currency of the purchase.
     * @param {string} orderId - Unique transaction ID.
     * @param {string} customerEmail - Customer's email address.
     * @param {number} transportPrice - Additional transport price.
     * @param {string|null} mode - Mode of the order.
     */
    orderSubmit(value, currency, orderId, customerEmail, transportPrice = 0, mode = null) {
        // Standardize and hash the email
        const standardizedEmail = this.standardizeEmail(customerEmail);
        const hashedEmail = standardizedEmail ? this.hashData(standardizedEmail) : '';

        // Initialize the UET queue if not already present
        window.uetq = window.uetq || [];

        // Push the 'purchase' event with Enhanced Conversions data
        window.uetq.push("event", "purchase", {
            value: value,
            currency: currency,
            orderId: orderId,
            userData: {
                email: hashedEmail, // Include hashed email for Enhanced Conversions
            },
        });
    }
}

export default new MsManager();
