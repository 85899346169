import CryptoJS from "crypto-js";

class SklikManager {
    /**
     * Initializes any necessary configurations for SklikManager.
     * Currently, no specific initialization is required.
     */
    init() {
        // Any initialization logic can go here if needed in the future
    }

    /**
     * Submits the order data to Sklik.
     * @param {number} value - The value of the purchase in CZK.
     * @param {string} customerEmail - Customer's raw email address.
     */
    orderSubmit(value, customerEmail) {
        if (window.sznIVA && window.sznIVA.IS && window.rc && window.rc.conversionHit) {
            // Hash the customer email using SHA-256 for privacy
            const hashedEmail = this.hashEmail(customerEmail);

            // Update identities with eid (hashed email)
            window.sznIVA.IS.updateIdentities({
                eid: hashedEmail, // Set to hashed email
            });

            // Configure the conversion hit
            const conversionConf = {
                id: 100003344, // Identifikátor konverze Sklik
                value: value,  // Hodnota objednávky v Kč
                consent: 1,    // Souhlas od návštěvníka na odeslání konverzního hitu
            };

            // Fire the conversion hit
            window.rc.conversionHit(conversionConf);
        } else {
            console.error('SklikManager Error: Sklik scripts are not fully loaded.');
        }
    }

    /**
     * Hashes the email using SHA-256.
     * @param {string} email - The customer's email address.
     * @returns {string} - The hashed email.
     */
    hashEmail(email) {
        if (!email) return '';
        return CryptoJS.SHA256(email.trim().toLowerCase()).toString();
    }
}

export default new SklikManager();
