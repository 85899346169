import SettingsStore from "../stores/Settings/SettingsStore";
import CryptoJS from "crypto-js"; // Ensure CryptoJS is installed

class gptManager {
    gtag = () => {};

    /**
     * Initializes the Google Tag Manager (gtag.js) for Google Ads.
     */
    init() {
        const { gTagAw } = SettingsStore.settings;

        window.dataLayer = window.dataLayer || [];
        function gtag() {
            dataLayer.push(arguments);
        }
        gtag("js", new Date());

        // Uncomment and configure if using Universal Analytics
        // gtag('config', gTagUa); // UA-7197138-1
        gtag("config", gTagAw); // AW-1069358169

        this.gtag = gtag;
    }

    /**
     * Initializes the PostAffTracker.
     */
    initPostAffTracker() {
        const { postAffTrackerId } = SettingsStore.settings;
        PostAffTracker.setAccountId(postAffTrackerId);
        try {
            PostAffTracker.track();
        } catch (err) {
            console.error("PostAffTracker Error:", err);
        }
    }

    /**
     * Submits the order data to various tracking platforms, including Google Ads Enhanced Conversions
     * @param {number} value - The value of the purchase
     * @param {string} currency - The currency of the purchase
     * @param {string} transaction_id - Unique transaction ID
     * @param {string} customerEmail - Customer's email address
     * @param {number} transportPrice - Additional transport price
     * @param {string|null} mode - Mode of the order
     */
    orderSubmit(value, currency, transaction_id, customerEmail, transportPrice = 0, mode = null) {
        // Google Ads Conversion Tracking with Enhanced Conversions
        if (typeof this.gtag !== "undefined") {
            const standardizedEmail = this.standardizeEmail(customerEmail);
            const hashedEmail = this.hashData(standardizedEmail);

            this.gtag("event", "conversion", {
                send_to: "AW-1069358169/5jkRCPnNwwgQ2bj0_QM", 
                value: value,
                currency: currency,
                transaction_id: transaction_id,
                user_data: {
                    email: hashedEmail, // Include hashed email for Enhanced Conversions
                },
            });
        }

        // Microsoft Ads Conversion Tracking via MsManager (Assuming similar handling)
        // If MsManager is handling Microsoft Ads, ensure it receives the necessary parameters
        // Example:
        // MsManager.orderSubmit(value, currency, transaction_id, customerEmail, transportPrice, mode);

        // PostAffTracker Conversion Tracking
        const sale = PostAffTracker.createSale();

        sale.setTotalCost(transportPrice);
        sale.setOrderID(transaction_id);
        sale.setProductID(mode);
        sale.setStatus("P");
        PostAffTracker.register();

        // Facebook Pixel Conversion Tracking
        if (typeof window !== "undefined" && window.fbq) {
            window.fbq('track', 'Purchase', {
                value: value,
                currency: currency,
                content_ids: [transaction_id], 
                content_type: 'product',
                // Optionally, include email if Facebook Pixel supports and you have consent
                // Note: Verify Facebook Pixel's data handling policies before sending email
                // email: hashedEmail,
            });
        }
    }

    /**
     * Standardizes the email address by removing unnecessary characters and formatting
     * @param {string} email - The raw email address
     * @returns {string} - The standardized email address
     */
    standardizeEmail(email) {
        if (!email) return '';

        // Remove whitespaces and accents
        let standardized = email.normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/\s+/g, '');

        // Remove everything between '+' and '@'
        standardized = standardized.replace(/\+.*@/, '@');

        // Remove periods before '@' and ensure no trailing periods
        standardized = standardized.replace(/\.(?=.*@)/g, '').replace(/\.+$/, '');

        // Convert to lowercase and ensure it contains '@'
        standardized = standardized.toLowerCase();
        if (!standardized.includes('@')) return '';

        return standardized;
    }

    /**
     * Hashes the standardized email using SHA-256
     * @param {string} data - The standardized email
     * @returns {string} - The SHA-256 hash of the email
     */
    hashData(data) {
        return CryptoJS.SHA256(data).toString();
    }
}

export default new gptManager();
