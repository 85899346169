import React, { Component } from "react";
import { Dropdown, Drawer, Button } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { observer } from "mobx-react";
import { withRouter } from "next/router";
import LanguageStore from "../../stores/Language/LanguageStore";
import i18n from "../../core/i18n";
import { windowLocation } from "../Router.tsx"; //
import UserListStore from "../../stores/User/UserListStore";

/**
 * Helper function to map language codes for display
 * Ensures 'cz' is mapped to 'cs' for UI purposes
 */
function mapLanguageCode(lang) {
    return lang === "cz" ? "cs" : lang;
}

class LanguagesMenu extends Component {
    state = {
        isMobile: false,
        drawerVisible: false,
    };

    componentDidMount() {
        this.handleResize();
        window.addEventListener("resize", this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
    }

    handleResize = () => {
        this.setState({ isMobile: window.innerWidth <= 1099 });
    };

    showDrawer = () => {
        this.setState({ drawerVisible: true });
    };

    closeDrawer = () => {
        this.setState({ drawerVisible: false });
    };

    /**
     * Handles language change based on the current route.
     * Maps 'cs' to 'cz' before passing to windowLocation to match LanguageStore.languages
     */
    handleLanguageChange = (languageKey) => {
        const { router } = this.props;

        // Map 'cz' to 'cs' for internal processing
        const internalLangKey = languageKey === "cz" ? "cs" : languageKey;

        // Save the selected language
        if (UserListStore.isLoggingIn) {
            UserListStore.save({ language: languageKey });
        }

        // Remove any inline styles if previously added
        document.body.removeAttribute("style");

        // Handle routing with the selected language
        let route = "index"; // Default to 'index'

        if (router.route.includes("/[lang]/tours")) {
            // Navigate to /[newLang]/tours
            route = "tours";
            windowLocation(route, { ...router.query, lang: internalLangKey });
        } else if (router.route === "/[lang]") {
            // Current route is the index page
            route = "index";
            windowLocation(route, { lang: internalLangKey });
        } else if (router.route.includes("/[lang]/")) {
            // Other language-specific routes
            route = router.route.replace("/[lang]/", "").replace(/^\//, "");
            if (route === "") {
                route = "index"; // Fallback to 'index' if route is empty
            }
            windowLocation(route, { ...router.query, lang: internalLangKey });
        }

        // Close the drawer if it's open
        this.closeDrawer();
    };

    render() {
        const { router } = this.props;
        const { isMobile, drawerVisible } = this.state;

        // Move 'en' to the top of the languages list
        const sortedLanguages = [
            ...LanguageStore.languages.filter((lang) => lang.language === "en"),
            ...LanguageStore.languages.filter((lang) => lang.language !== "en"),
        ];

        // Create dropdown items with 'cs' mapped from 'cz'
        let items = [];
        sortedLanguages.forEach((language, i) => {
            const displayKey = mapLanguageCode(language.language);
            const imageSource = `/img/flags/${displayKey}.png`;
            items[i] = {
                key: displayKey, // Use 'cs' instead of 'cz' for display
                label: language.nativeName,
                icon: <img src={imageSource} alt={displayKey} width="20" />,
            };
        });

        const onClick = ({ key }) => {
            this.handleLanguageChange(key);
        };

        // Drawer content without search functionality
        const drawerContent = (
            <div style={{ padding: "20px", textAlign: "center" }}>
                {sortedLanguages.map((language) => {
                    const displayKey = mapLanguageCode(language.language);
                    const imageSource = `/img/flags/${displayKey}.png`;
                    return (
                        <div
                            key={displayKey} // Use 'cs' instead of 'cz'
                            onClick={() => this.handleLanguageChange(displayKey)}
                            style={{
                                display: "inline-block",
                                margin: "10px",
                                cursor: "pointer",
                            }}
                        >
                            <img src={imageSource} alt={displayKey} width="30" />
                            <p>{language.nativeName}</p>
                        </div>
                    );
                })}
            </div>
        );

        // Determine the current language code for the button icon
        const currentLangDisplay = mapLanguageCode(LanguageStore.lang);

        return (
            <div className="settings-menu settings-menu--language">
                <span className="settings-menu hide-on-smaller-screen" style={{ marginLeft: "5px", marginRight: "8px" }}>{i18n.t("Jazyk")}</span>

                {/* Mobile Drawer */}
                {isMobile ? (
                    <>
                        <Button
                            type="default"
                            style={{
                                border: "0px",
                                padding: "0 10px",
                                color: "#c0392b",
                                fontSize: "15px",
                                display: "flex",
                                alignItems: "center",
                            }}
                            icon={
                                <img style={{
             
                                    display: "flex",
                                    alignItems: "center",
                                }}
                                    src={`/img/flags/${currentLangDisplay}.png`} // Use 'cs' instead of 'cz'
                                    width="17"
                                    alt={i18n.t(LanguageStore.getLocaleName(LanguageStore.lang))}
                                    aria-label={i18n.t(LanguageStore.getLocaleName(LanguageStore.lang))}
                                />
                            }
                            onClick={this.showDrawer}
                        >
                            
                                <DownOutlined className="settings-menu hide-on-small-mobile"/>
                            
                        </Button>

                        <Drawer
                            title={i18n.t("Select Language")}
                            placement="right"
                            closable
                            onClose={this.closeDrawer}
                            open={drawerVisible}
                            styles={{ body: { padding: 0 } }}
                        >
                            {drawerContent}
                        </Drawer>
                    </>
                ) : (
                    // Desktop Dropdown
                    <Dropdown
                        menu={{
                            items,
                            onClick,
                        }}
                        trigger={["click"]}
                    >
                        <a onClick={(e) => e.preventDefault()} style={{ display: "flex", alignItems: "center" }}>
                            <span className="header__language-hide-mobile">
                                {i18n.t(LanguageStore.getLocaleName(LanguageStore.lang))}
                            </span>
                            <DownOutlined />
                        </a>
                    </Dropdown>
                )}
            </div>
        );
    }
}

export default withRouter(observer(LanguagesMenu));
